// App.js - WEB
import React, { Component } from 'react';
import { View } from 'react-native';
import firebase from 'firebase';
import { connect } from 'react-firebase';
import { BuilderProvider } from '@builder/component-library';

import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";

import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import BulkUploading from "../../blocks/bulkuploading/src/BulkUploading";
import SocialMediaAccountLogin from "../../blocks/social-media-account/src/SocialMediaAccountLogin";
import SocialMediaAccountRegistration from "../../blocks/social-media-account/src/SocialMediaAccountRegistration";
import AssessmentTest from "../../blocks/assessmenttest/src/AssessmentTest";
import WeatherIntegration from "../../blocks/weatherintegration/src/WeatherIntegration";
import LocationFinder from "../../blocks/weatherintegration/src/LocationFinder";
import WeatherDetails from "../../blocks/weatherintegration/src/WeatherDetails";
import PhotoLibrary from "../../blocks/photolibrary/src/PhotoLibrary";
import AuctionBidding from "../../blocks/auctionbidding/src/AuctionBidding";
import TermsConditions from "../../blocks/termsconditions/src/TermsConditions";
import TermsConditionsDetail from "../../blocks/termsconditions/src/TermsConditionsDetail";
import TermsConditionsUsers from "../../blocks/termsconditions/src/TermsConditionsUsers";
import Emailnotifications2 from "../../blocks/emailnotifications2/src/Emailnotifications2";
import EventRegistration from "../../blocks/eventregistration/src/EventRegistration";
import FeatureSettings from "../../blocks/featuresettings/src/FeatureSettings";
import QuestionBank from "../../blocks/questionbank/src/QuestionBank";
import Repost from "../../blocks/repost/src/Repost";
import AutomaticCheckoutCalculation from "../../blocks/automaticcheckoutcalculation/src/AutomaticCheckoutCalculation";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";
import VideoEmbeddingYoutube from "../../blocks/videoembeddingyoutube/src/VideoEmbeddingYoutube";
import ApplePayIntegration from "../../blocks/applepayintegration/src/ApplePayIntegration";
import Trending from "../../blocks/trending/src/Trending";
import Reservations from "../../blocks/reservations/src/Reservations";
import ShiprocketIntegrate from "../../blocks/shiprocketintegrate/src/ShiprocketIntegrate.web";
import StripeConnect from "../../blocks/stripepayments/src/StripeConnect.web";
import StripeConnectSuccess from "../../blocks/stripepayments/src/StripeConnectSuccess.web";
import Shiprocketmena from "../../blocks/shiprocketmena/src/Shiprocket.web";
import UpdateAddress from "../../blocks/shiprocketintegrate/src/UpdateAddress.web";
import TrackOrder from "../../blocks/shiprocketintegrate/src/TrackOrder.web";
import OrderDetails from "../../blocks/shiprocketintegrate/src/OrderDetails.web";
import RazorpayIntegration from "../../blocks/razorpayintegration/src/RazorpayIntegration.web";
import RazorpayIntegrationMobile from "../../blocks/razorpayintegration/src/RazorpayIntegration";
import RazorpayIntegration6 from "../../blocks/razorpayintegration/src/RazorpayIntegration6.web";

const routeMap = {
  RazorpayIntegration: {
    component: RazorpayIntegration,
    path: "/RazorpayIntegration"
  },
  RazorpayIntegrationMobile: {
    component: RazorpayIntegrationMobile,
    path: "/RazorpayIntegrationMobile"
  },
  RazorpayIntegration6: {
    component: RazorpayIntegration6,
    path: "/RazorpayIntegration6"
  },
  OrderDetails:{
    component:OrderDetails,
   path:"/OrderDetails"},
   TrackOrder:{
    component:TrackOrder,
   path:"/TrackOrder"},
   UpdateAddress:{
    component:UpdateAddress,
   path:"/UpdateAddress"},
  Shiprocketmena:{
    component:Shiprocketmena,
   path:"/Shiprocketmena"},
  StripeConnectSuccess:{
    component:StripeConnectSuccess,
   path:"/StripeConnectSuccess"},
  StripeConnect:{
    component:StripeConnect,
   path:"/StripeConnect"},
  ShiprocketIntegrate:{
    component:ShiprocketIntegrate,
   path:"/ShiprocketIntegrate"},
BulkUploading:{
 component:BulkUploading,
path:"/BulkUploading"},
SocialMediaAccountLogin:{
 component:SocialMediaAccountLogin,
path:"/SocialMediaAccountLogin"},
SocialMediaAccountRegistration:{
 component:SocialMediaAccountRegistration,
path:"/SocialMediaAccountRegistration"},
AssessmentTest:{
 component:AssessmentTest,
path:"/AssessmentTest"},
WeatherIntegration:{
 component:WeatherIntegration,
path:"/WeatherIntegration"},
LocationFinder:{
 component:LocationFinder,
path:"/LocationFinder"},
WeatherDetails:{
 component:WeatherDetails,
path:"/WeatherDetails"},
PhotoLibrary:{
 component:PhotoLibrary,
path:"/PhotoLibrary"},
AuctionBidding:{
 component:AuctionBidding,
path:"/AuctionBidding"},
TermsConditions:{
 component:TermsConditions,
path:"/TermsConditions"},
TermsConditionsDetail:{
 component:TermsConditionsDetail,
path:"/TermsConditionsDetail"},
TermsConditionsUsers:{
 component:TermsConditionsUsers,
path:"/TermsConditionsUsers"},
Emailnotifications2:{
 component:Emailnotifications2,
path:"/Emailnotifications2"},
EventRegistration:{
 component:EventRegistration,
path:"/EventRegistration"},
FeatureSettings:{
 component:FeatureSettings,
path:"/FeatureSettings"},
QuestionBank:{
 component:QuestionBank,
path:"/QuestionBank"},
Repost:{
 component:Repost,
path:"/Repost"},
AutomaticCheckoutCalculation:{
 component:AutomaticCheckoutCalculation,
path:"/AutomaticCheckoutCalculation"},
Categoriessubcategories:{
 component:Categoriessubcategories,
path:"/Categoriessubcategories"},
VideoEmbeddingYoutube:{
 component:VideoEmbeddingYoutube,
path:"/VideoEmbeddingYoutube"},
ApplePayIntegration:{
 component:ApplePayIntegration,
path:"/ApplePayIntegration"},
Trending:{
 component:Trending,
path:"/Trending"},
Reservations:{
 component:Reservations,
path:"/Reservations"},

  Home: {
    component: HomeScreen,
    path: '/',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true
  }

};

const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});

class App extends Component {
   
  render() {

    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');
    
    return (
      <BuilderProvider>
        <View style={{ height: '100vh', width: '100vw' }}>
          <TopNav />
          {WebRoutesGenerator({ routeMap })}
          <ModalContainer />
        </View>
      </BuilderProvider>
    );
  }
}

export default App;
