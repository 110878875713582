import React from "react";
// Customizable Area Start
import RazorpayIntegration6Controller, { Props } from "./RazorpayIntegration6Controller";
// Customizable Area End

export default class RazorpayIntegration6 extends RazorpayIntegration6Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
    <></>
    );
    // Customizable Area End
  }
}