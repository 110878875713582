import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { setStorageData } from "framework/src/Utilities";
import { handleResponseMessage } from "./helpers/handleResponseMessage";
import {
  IApiCall,
  ICustomerData,
  IHistoryCartOrders,
  Item,
  IVerifyCallApi,
  Order,
} from "./types";
export interface APIPayloadType {
  contentType: string;
  method: string;
  endPoint: string;
  body?: object;
}

interface ItemAttributes {
  key: string | null;
  amount: number;
  status: string | null;
  razorpay_order_id: string | null;
  receipt: string | null;
  account_id: number;
  order_id: number;
  entity: string | null;
  amount_paid: number | null;
  amount_due: number | null;
  currency: string;
  notes: string | null;
}

interface ItemCustomer {
  id: string;
  type: string;
  attributes: ItemAttributes;
}

interface Data {
  item: ItemCustomer;
}

interface RazorpayOrderResponse {
  data: Data;
}
declare global {
  interface RazorpayOptions {
    key: string;
    amount: number;
    currency: string;
    name: string;
    description: string;
    order_id: string;
    handler: (response: IRazorResp) => void;
    prefill: {
      name: string;
      email: string;
      contact: string;
    };
    theme: {
      color: string;
    };
  }

  interface IRazorResp {
    razorpay_payment_id: string;
    razorpay_order_id: string;
    razorpay_signature: string;
  }

  interface RazorpayInstance {
    open(): void;
  }

  interface Window {
    Razorpay: new (options: RazorpayOptions) => RazorpayInstance;
  }
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  history: IHistoryCartOrders;
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  cartOrderId: string;
  cartToken: string;
  cartOrderList: Array<Order>;
  cartOrderItems: Array<Item>;
  isVisible: boolean;
  cartId: string;
  razorId: string;
  razorAmount: number;
  customerData: ICustomerData;
  razorpayAccessToken: string
  razorpayData: RazorpayOrderResponse;
  razorpayKey: string;
  isCheckoutOpen: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class RazorpayIntegrationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  deleteApiOrderItemCallId: string = "";
  getApiOrdersCallId: string = "";
  createOrderCallId: string = "";
  createTokenApiCallId: string = "";
  getRazorpayConfigCallId: string = "";
  razorRespApiCallId: string = "";
  postAnalyticsRespApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      cartOrderId: "",
      cartToken: "",
      cartOrderList: [],
      cartOrderItems: [],
      isVisible: false,
      cartId: "",
      razorId: "",
      razorAmount: 0,
      customerData: {
        data: {
          id: "",
          type: "",
          attributes: {
            activated: false,
            country_code: null,
            email: "",
            first_name: "",
            full_phone_number: "",
            last_name: "",
            phone_number: null,
            type: "",
            created_at: "",
            updated_at: "",
            device_id: null,
            unique_auth_id: "",
          },
        },
      },
      razorpayAccessToken: "",
      razorpayData: {
        data: {
          item: {
            id: "",
            type: "",
            attributes: {
              key: null,
              amount: 212,
              status: null,
              razorpay_order_id: null,
              receipt: null,
              account_id: 0,
              order_id: 0,
              entity: null,
              amount_paid: null,
              amount_due: null,
              currency: "INR",
              notes: null
            }
          },
        }
      },
      razorpayKey: "",
      isCheckoutOpen: false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const requestCallDataId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage),
      );

      const responseSuccessWeb = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage),
      );

      const responseErrorWeb = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage),
      );
      switch (requestCallDataId) {
        case this.getApiOrdersCallId: {
          handleResponseMessage({
            responseJson: responseSuccessWeb,
            errorJson: responseErrorWeb,
            onSuccess: () => {
              this.setState({ cartOrderList: responseSuccessWeb?.data });
            },
            onFail: () => {
              this.setState({ cartOrderList: [] });
              this.showAlert(
                `Error`,
                `${responseSuccessWeb.errors
                  ? Object.entries(responseSuccessWeb.errors[0])[0][1]
                  : configJSON.noDataErrorText
                }`,
              );
            },
          });
          break;
        }
        case this.deleteApiOrderItemCallId: {
          this.deleteItem(responseSuccessWeb);
          break;
        }
        case this.createOrderCallId: {
          handleResponseMessage({
            responseJson: responseSuccessWeb,
            errorJson: responseErrorWeb,
            onSuccess: () => {
              this.setState({
                razorpayData: {
                  data: {
                    item: responseSuccessWeb.data,
                  }
                },
                isCheckoutOpen: true
              });
            },
            onFail: () => {
              this.showAlert(
                `Error`,
                `${responseSuccessWeb?.error
                  ? responseSuccessWeb?.error
                  : configJSON.createRazorOrderError
                }`,
              );
            },
          });
          break;
        }
        case this.createTokenApiCallId:
          this.createTokenAPiResponse(responseSuccessWeb);
          break;
        case this.getRazorpayConfigCallId:
          this.razorpayConfig(responseSuccessWeb);
          break;
        case this.razorRespApiCallId:
          this.razorpayVerification(responseSuccessWeb);
          break;
      }
    }
    // Customizable Area End
  }
  // Customizable Area Start
  async componentDidMount() {
    this.onRegisterRazorpay();
  }

  createTokenAPiResponse = async (responseJson: { token: string }) => {
    setStorageData("token", responseJson.token);
    const cartToken = responseJson.token;
    this.setState({ cartToken: cartToken }, () => {
      this.getOrders(cartToken);
      this.getRazorpayConfig(cartToken);
    });
  }

  razorpayConfig = async (responseJson: { razorpay_access_token: string; razorpay_key: string } | { error: string }) => {
    if ("error" in responseJson) {
      this.showAlert("Error", responseJson.error);
    } else {
      this.setState({
        razorpayAccessToken: responseJson.razorpay_access_token,
        razorpayKey: responseJson.razorpay_key
      });
    }
  };

  deleteItem = async (responseJson: { message: string; } | { error: string }) => {
    if ("error" in responseJson) {
      this.showAlert(`Error`, `${configJSON.deleteOrderFailedText}`);
    } else {
      this.getOrders(this.state.cartToken);
      this.showAlert("Note", responseJson.message);
    }
  };

  razorpayVerification = async (responseJson: { message: string; } | { error: string }) => {
    if ("error" in responseJson) {
      this.setState({
        isCheckoutOpen: false,
        isVisible: false
      })
      this.showAlert(`Error`, responseJson.error + "in verification");
    } else {
      this.setState({
        isCheckoutOpen: false,
        isVisible: false
      })
      this.showAlert("Success", configJSON.msgPaymentSuccess);
    }
  };

  getOrders = (token: string) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      "token": token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );
    const params = { filter_by: "scheduled" };

    this.getApiOrdersCallId = requestMessage.messageId;
    const urlParams = new URLSearchParams(params).toString();

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod,
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getOrdersApiEndPoint}?${urlParams}`,
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header),
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleRazorApiCall = () => {
    const body = {
      data: {
        amount: `${this.state.cartOrderList &&
          (this.state.cartOrderList[0].attributes.total_fees +
            this.state.cartOrderList[0].attributes.total_tax) *
          100
          }`,
        currency: `${configJSON.currencyValue}`,
        notes: {
          key1: "value1",
          key2: "value2",
        },
      },
    };
    this.handleDoCreateObject({
      contentType: configJSON.apiContentType,
      method: configJSON.postApiMethod,
      endPoint: `bx_block_razorpay/razorpay/${this.state.cartOrderId}/create_order`,
      body: body,
      token: this.state.cartToken,
    });
  };
  handleDoCreateObject = (data: IApiCall) => {
    const { contentType, method, endPoint, body, token } = data;
    const header = {
      "Content-Type": contentType,
      "token": token,
      "Authorization": this.state.razorpayAccessToken
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );
    this.createOrderCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header),
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint,
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method,
    );
    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body),
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  hideModal = () => {
    this.setState({ isVisible: !this.state.isVisible });
  };

  hideCheckoutModal = () => {
    this.setState({ isCheckoutOpen: !this.state.isCheckoutOpen });
  };

  showOrderApi = (responseJson: Item[], cartOrderId: string) => {
    responseJson &&
      this.setState({
        cartOrderItems: responseJson,
        isVisible: !this.state.isVisible,
        cartOrderId,
      });
  };
  deleteOrderItem = (orderId: string, orderItemId: number) => {
    this.setState({ isVisible: !this.state.isVisible, cartOrderId: orderId });
    const header = {
      "Content-Type": configJSON.apiContentType,
      "token": this.state.cartToken,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );
    this.deleteApiOrderItemCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteApiMethod,
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getOrdersApiEndPoint}/${orderId}/order_items/${orderItemId}`,
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header),
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  handleCheckout = async (checkItem: Order) => {
    this.setState(
      {
        cartOrderId: checkItem.id,
        customerData: checkItem.attributes?.customer,
      },
      () => this.handleRazorApiCall(),
    );
  };

  getRazorpayConfig = (token: string) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      "token": token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );

    this.getRazorpayConfigCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod,
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.razorpayConfigApiEndPoint,
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header),
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  onRegisterRazorpay = async () => {
    this.createTokenApiCallId = await this.apiCallRazorpay({
      contentType: configJSON.apiContentType,
      method: configJSON.postApiMethod,
      endPoint: `${configJSON.createTokenApiEndPoint}email=example@user.com&password=password1234`
    });
  };

  apiCallRazorpay = async (data: APIPayloadType) => {
    const { contentType, method, endPoint, body } = data;
    const header = {
      "Content-Type": contentType,
    };
    const requestMessageApiRazorpay = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessageApiRazorpay.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessageApiRazorpay.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessageApiRazorpay.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    requestMessageApiRazorpay.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    runEngine.sendMessage(requestMessageApiRazorpay.id, requestMessageApiRazorpay);
    return requestMessageApiRazorpay.messageId;
  };

  handlePayment = async () => {
    const customerDetails =
      this.state.customerData.data.attributes;
    const currency = this.state.razorpayData.data.item.attributes.currency;
    const options = {
      key: this.state.razorpayKey,
      amount: this.state.razorpayData.data.item.attributes.amount,
      currency: currency,
      name: `${configJSON.projectNameText}`,
      description: `${configJSON.description}`,
      order_id: `${this.state.razorpayData.data.item.attributes.razorpay_order_id}`,
      handler: (resp: IRazorResp) => {
        this.handleAnalyticsResponse(resp);
      },
      prefill: {
        name: `${customerDetails.first_name}${" "}${customerDetails.last_name}`,
        email: `${customerDetails.email}`,
        contact: `${customerDetails.full_phone_number}`,
      },
      theme: {
        color: `${configJSON.themeColor}`,
      },
    };

    const rzpOpen = new window.Razorpay(options);
    rzpOpen.open();
  }

  handleSuccessResponse = (resp: IRazorResp) => {
    const body = resp;
    this.handleDoCallApi({
      contentType: configJSON.apiContentType,
      method: configJSON.postApiMethod,
      endPoint: `${configJSON.verifyRazorPaymentEndPoint}`,
      body: body,
      token: this.state.cartToken,
    });
  };

  handleAnalyticsResponse = (resp: IRazorResp) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      "token": this.state.cartToken,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );
    this.postAnalyticsRespApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header),
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_razorpay/razorpay/${this.state.cartOrderId}/send_analytics?razorpay_order_id=${resp.razorpay_order_id}`,
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethod,
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  handleDoCallApi = (data: IVerifyCallApi) => {
    const { contentType, method, endPoint, body, token } = data;
    const header = {
      "Content-Type": contentType,
      "token": token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );
    this.razorRespApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header),
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint,
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method,
    );
    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body),
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };
  // Customizable Area End
}
