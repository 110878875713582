const mockCreateOrderRes = {
  data: {
    id: "",
    type: "",
    attributes: {
      key: "",
      amount: 0,
      status: "",
      razorpay_order_id: "",
      receipt: "1704ad8d83a74e43132f8a9549bf3397",
      account_id: 1,
      order_id: 1,
      entity: "order",
      amount_paid: "0.0",
      amount_due: "50000.0",
      currency: "INR",
      notes: {
        key1: "value1",
        key2: "value2",
      },
    },
  },
};

const mockCreateOrderResError = {
  error: "Params Amount or currency missing!",
};

const mockCreateOrderResError1 = {
  message: "Params Amount or currency missing!",
};

const mockCreateOrderResWithData = {
  data: {
    id: "105",
    type: "razorpay_order",
    attributes: {
      key: "rzp_test_Feaa8XmuED2hRE",
      amount: 50000.0,
      status: "created",
      razorpay_order_id: "order_MILI4IWQMYnTcU",
      receipt: "1704ad8d83a74e43132f8a9549bf3397",
      account_id: 1,
      order_id: 1,
      entity: "order",
      amount_paid: "0.0",
      amount_due: "50000.0",
      currency: "INR",
      notes: {
        key1: "value1",
        key2: "value2",
      },
    },
  },
};
const mockGetOrderRes = {
  data: [
    {
      id: "9",
      type: "order",
      attributes: {
        status: "completed",
        total_fees: 1,
        total_items: 1,
        total_tax: 0,
        source: null,
        length: 2,
        breadth: 2,
        weight: 2,
        height: 2,
        ship_rocket_order_id: "370306925",
        ship_rocket_shipment_id: "369680348",
        ship_rocket_awb_code: "",
        ship_rocket_label_url: null,
        ship_rocket_manifest_url: null,
        ship_rocket_invoice_url: null,
        is_request_pickup: false,
        customer: {
          data: {
            id: "1",
            type: "account",
            attributes: {
              activated: true,
              country_code: null,
              email: "sanskar@yopmail.com",
              first_name: "sanskar",
              full_phone_number: "9755853847",
              last_name: "songara",
              phone_number: null,
              type: "EmailAccount",
              created_at: "2023-06-06T11:37:47.580Z",
              updated_at: "2023-06-06T11:37:47.580Z",
              device_id: null,
              unique_auth_id: "SmnhkFR9yGkEpzFokgY5uwtt",
            },
          },
        },
        address: {
          data: {
            id: "53",
            type: "address",
            attributes: {
              latitude: null,
              longitude: null,
              address: "IGNOU Road1",
              address_type: "Home",
              city: "Saket",
              pincode: 424000,
              state: "Delhi",
              address2: "Neb Sarai",
              country: "India",
            },
          },
        },
        order_items: {
          data: [
            {
              id: "57",
              type: "order_item",
              attributes: {
                price: 1,
                quantity: 1,
                taxable: true,
                taxable_value: 0,
                other_charges: null,
                catalogue: {
                  data: {
                    id: "25",
                    type: "catalogue",
                    attributes: {
                      sub_category: {
                        id: 1,
                        name: "Pre Primary (kg)",
                        created_at: "2023-05-26T10:41:53.313Z",
                        updated_at: "2023-05-26T10:41:53.313Z",
                        parent_id: null,
                        rank: null,
                      },
                      brand: null,
                      tags: [],
                      reviews: [],
                      name: "demo",
                      sku: "dummy_sku",
                      description: "demo description",
                      manufacture_date: "2000-12-31T00:00:00.000Z",
                      length: null,
                      breadth: null,
                      height: null,
                      stock_qty: null,
                      availability: null,
                      weight: null,
                      price: null,
                      recommended: null,
                      on_sale: null,
                      sale_price: "1.0",
                      discount: null,
                      category: {
                        id: "1",
                        type: "category",
                        attributes: {
                          id: 1,
                          name: "K12",
                          dark_icon: null,
                          dark_icon_active: null,
                          dark_icon_inactive: null,
                          light_icon: null,
                          light_icon_active: null,
                          light_icon_inactive: null,
                          rank: null,
                          created_at: "2023-05-26T10:41:53.259Z",
                          updated_at: "2023-05-26T10:41:53.259Z",
                          selected_sub_categories: null,
                        },
                      },
                      images: null,
                      average_rating: 0,
                      catalogue_variants: [],
                    },
                  },
                },
              },
            },
          ],
        },
        return_order: null,
      },
    },
    {
      id: "3",
      type: "order",
      attributes: {
        status: "completed",
        total_fees: 2000,
        total_items: 1,
        total_tax: 1,
        source: null,
        length: null,
        breadth: null,
        weight: null,
        height: null,
        ship_rocket_order_id: "361312841",
        ship_rocket_shipment_id: "360686277",
        ship_rocket_awb_code: "14326384801742",
        ship_rocket_label_url: null,
        ship_rocket_manifest_url: null,
        ship_rocket_invoice_url: null,
        is_request_pickup: false,
        customer: {
          data: {
            id: "1",
            type: "account",
            attributes: {
              activated: true,
              country_code: null,
              email: "sanskar@yopmail.com",
              first_name: "sanskar",
              full_phone_number: "9755853847",
              last_name: "songara",
              phone_number: null,
              type: "EmailAccount",
              created_at: "2023-06-06T11:37:47.580Z",
              updated_at: "2023-06-06T11:37:47.580Z",
              device_id: null,
              unique_auth_id: "SmnhkFR9yGkEpzFokgY5uwtt",
            },
          },
        },
        address: {
          data: {
            id: "12",
            type: "address",
            attributes: {
              latitude: null,
              longitude: null,
              address: "new address 1 update",
              address_type: "Home",
              city: "new city2",
              pincode: 422,
              state: "Maharashtra",
              address2: "new address 2 update",
              country: "India",
            },
          },
        },
        order_items: {
          data: [
            {
              id: "24",
              type: "order_item",
              attributes: {
                price: 2000,
                quantity: 1,
                taxable: true,
                taxable_value: 1,
                other_charges: null,
                catalogue: {
                  data: {
                    id: "1",
                    type: "catalogue",
                    attributes: {
                      sub_category: {
                        id: 1,
                        name: "Pre Primary (kg)",
                        created_at: "2023-05-26T10:41:53.313Z",
                        updated_at: "2023-05-26T10:41:53.313Z",
                        parent_id: null,
                        rank: null,
                      },
                      brand: null,
                      tags: [],
                      reviews: [],
                      name: "demo",
                      sku: "dummy_sku",
                      description: "demo description",
                      manufacture_date: "2000-12-31T00:00:00.000Z",
                      length: null,
                      breadth: null,
                      height: null,
                      stock_qty: null,
                      availability: null,
                      weight: null,
                      price: null,
                      recommended: null,
                      on_sale: null,
                      sale_price: "2000.0",
                      discount: null,
                      category: {
                        id: "1",
                        type: "category",
                        attributes: {
                          id: 1,
                          name: "K12",
                          dark_icon: null,
                          dark_icon_active: null,
                          dark_icon_inactive: null,
                          light_icon: null,
                          light_icon_active: null,
                          light_icon_inactive: null,
                          rank: null,
                          created_at: "2023-05-26T10:41:53.259Z",
                          updated_at: "2023-05-26T10:41:53.259Z",
                          selected_sub_categories: null,
                        },
                      },
                      images: null,
                      average_rating: 0,
                      catalogue_variants: [],
                    },
                  },
                },
              },
            },
          ],
        },
        return_order: null,
      },
    },
    {
      id: "1",
      type: "order",
      attributes: {
        status: "completed",
        total_fees: 222000,
        total_items: 113,
        total_tax: 123365,
        source: null,
        length: 1,
        breadth: 1,
        weight: 1,
        height: 1,
        ship_rocket_order_id: "355976034",
        ship_rocket_shipment_id: "355351715",
        ship_rocket_awb_code: "",
        ship_rocket_label_url: null,
        ship_rocket_manifest_url: null,
        ship_rocket_invoice_url:
          "https://s3-ap-south-1.amazonaws.com/kr-shipmultichannel-mum/2765546/invoices/Retail000154af8cab0-ec26-4ffb-bf5a-976fe801d6b3.pdf",
        is_request_pickup: false,
        customer: {
          data: {
            id: "1",
            type: "account",
            attributes: {
              activated: true,
              country_code: null,
              email: "sanskar@yopmail.com",
              first_name: "sanskar",
              full_phone_number: "9755853847",
              last_name: "songara",
              phone_number: null,
              type: "EmailAccount",
              created_at: "2023-06-06T11:37:47.580Z",
              updated_at: "2023-06-06T11:37:47.580Z",
              device_id: null,
              unique_auth_id: "SmnhkFR9yGkEpzFokgY5uwtt",
            },
          },
        },
        address: {
          data: {
            id: "178",
            type: "address",
            attributes: {
              latitude: null,
              longitude: null,
              address: "address2 ",
              address_type: "Other",
              city: "Varanasi",
              pincode: 382632,
              state: "Uttar Pradesh ",
              address2: "address2 update",
              country: "India",
            },
          },
        },
        order_items: {
          data: [
            {
              id: "17",
              type: "order_item",
              attributes: {
                price: 2000,
                quantity: 111,
                taxable: true,
                taxable_value: 1111,
                other_charges: null,
                catalogue: {
                  data: {
                    id: "1",
                    type: "catalogue",
                    attributes: {
                      sub_category: {
                        id: 1,
                        name: "Pre Primary (kg)",
                        created_at: "2023-05-26T10:41:53.313Z",
                        updated_at: "2023-05-26T10:41:53.313Z",
                        parent_id: null,
                        rank: null,
                      },
                      brand: null,
                      tags: [],
                      reviews: [],
                      name: "demo",
                      sku: "dummy_sku",
                      description: "demo description",
                      manufacture_date: "2000-12-31T00:00:00.000Z",
                      length: null,
                      breadth: null,
                      height: null,
                      stock_qty: null,
                      availability: null,
                      weight: null,
                      price: null,
                      recommended: null,
                      on_sale: null,
                      sale_price: "2000.0",
                      discount: null,
                      category: {
                        id: "1",
                        type: "category",
                        attributes: {
                          id: 1,
                          name: "K12",
                          dark_icon: null,
                          dark_icon_active: null,
                          dark_icon_inactive: null,
                          light_icon: null,
                          light_icon_active: null,
                          light_icon_inactive: null,
                          rank: null,
                          created_at: "2023-05-26T10:41:53.259Z",
                          updated_at: "2023-05-26T10:41:53.259Z",
                          selected_sub_categories: null,
                        },
                      },
                      images: null,
                      average_rating: 0,
                      catalogue_variants: [],
                    },
                  },
                },
              },
            },
            {
              id: "18",
              type: "order_item",
              attributes: {
                price: 0,
                quantity: 2,
                taxable: true,
                taxable_value: 22,
                other_charges: null,
                catalogue: {
                  data: {
                    id: "2",
                    type: "catalogue",
                    attributes: {
                      sub_category: {
                        id: 1,
                        name: "Pre Primary (kg)",
                        created_at: "2023-05-26T10:41:53.313Z",
                        updated_at: "2023-05-26T10:41:53.313Z",
                        parent_id: null,
                        rank: null,
                      },
                      brand: null,
                      tags: [],
                      reviews: [],
                      name: "demo",
                      sku: "dummy_sku",
                      description: "demo description",
                      manufacture_date: "2000-12-31T00:00:00.000Z",
                      length: null,
                      breadth: null,
                      height: null,
                      stock_qty: null,
                      availability: null,
                      weight: null,
                      price: 2000,
                      recommended: null,
                      on_sale: null,
                      sale_price: null,
                      discount: null,
                      category: {
                        id: "1",
                        type: "category",
                        attributes: {
                          id: 1,
                          name: "K12",
                          dark_icon: null,
                          dark_icon_active: null,
                          dark_icon_inactive: null,
                          light_icon: null,
                          light_icon_active: null,
                          light_icon_inactive: null,
                          rank: null,
                          created_at: "2023-05-26T10:41:53.259Z",
                          updated_at: "2023-05-26T10:41:53.259Z",
                          selected_sub_categories: null,
                        },
                      },
                      images: null,
                      average_rating: 0,
                      catalogue_variants: [],
                    },
                  },
                },
              },
            },
          ],
        },
        return_order: null,
      },
    },
    {
      id: "52",
      type: "order",
      attributes: {
        status: "completed",
        total_fees: 4000,
        total_items: 2,
        total_tax: 44,
        source: null,
        length: 1,
        breadth: 1,
        weight: 1,
        height: 1,
        ship_rocket_order_id: "377804218",
        ship_rocket_shipment_id: "377170070",
        ship_rocket_awb_code: "",
        ship_rocket_label_url: null,
        ship_rocket_manifest_url: null,
        ship_rocket_invoice_url: null,
        is_request_pickup: false,
        customer: {
          data: {
            id: "1",
            type: "account",
            attributes: {
              activated: true,
              country_code: null,
              email: "sanskar@yopmail.com",
              first_name: "sanskar",
              full_phone_number: "9755853847",
              last_name: "songara",
              phone_number: null,
              type: "EmailAccount",
              created_at: "2023-06-06T11:37:47.580Z",
              updated_at: "2023-06-06T11:37:47.580Z",
              device_id: null,
              unique_auth_id: "SmnhkFR9yGkEpzFokgY5uwtt",
            },
          },
        },
        address: {
          data: {
            id: "273",
            type: "address",
            attributes: {
              latitude: null,
              longitude: null,
              address: "Sunrise Arcade",
              address_type: "Work",
              city: "Indore",
              pincode: 411001,
              state: "Madhya Pradesh",
              address2: "med road",
              country: "India",
            },
          },
        },
        order_items: {
          data: [
            {
              id: "168",
              type: "order_item",
              attributes: {
                price: 2000,
                quantity: 2,
                taxable: true,
                taxable_value: 22,
                other_charges: null,
                catalogue: {
                  data: {
                    id: "1",
                    type: "catalogue",
                    attributes: {
                      sub_category: {
                        id: 1,
                        name: "Pre Primary (kg)",
                        created_at: "2023-05-26T10:41:53.313Z",
                        updated_at: "2023-05-26T10:41:53.313Z",
                        parent_id: null,
                        rank: null,
                      },
                      brand: null,
                      tags: [],
                      reviews: [],
                      name: "demo",
                      sku: "dummy_sku",
                      description: "demo description",
                      manufacture_date: "2000-12-31T00:00:00.000Z",
                      length: null,
                      breadth: null,
                      height: null,
                      stock_qty: null,
                      availability: null,
                      weight: null,
                      price: null,
                      recommended: null,
                      on_sale: null,
                      sale_price: "2000.0",
                      discount: null,
                      category: {
                        id: "1",
                        type: "category",
                        attributes: {
                          id: 1,
                          name: "K12",
                          dark_icon: null,
                          dark_icon_active: null,
                          dark_icon_inactive: null,
                          light_icon: null,
                          light_icon_active: null,
                          light_icon_inactive: null,
                          rank: null,
                          created_at: "2023-05-26T10:41:53.259Z",
                          updated_at: "2023-05-26T10:41:53.259Z",
                          selected_sub_categories: null,
                        },
                      },
                      images: null,
                      average_rating: 0,
                      catalogue_variants: [],
                    },
                  },
                },
              },
            },
          ],
        },
        return_order: null,
      },
    },
  ],
};

const mockVerifySignatureErrorResponse1 = {
  error: "Some error occured",
};

const mockVerifySignatureSuccessResponse = {
  data: {
    id: "13",
    type: "verify_payment_signature",
    attributes: {
      status: true,
      rpay_order_id: "order_MHxKAjixDFmvTF",
      razorpay_payment_id: "pay_MHxL86Y0LvquRX",
      razorpay_signature:
        "443770e57550d853bf37ca0eb35edfed7511def73e0c6148e7ace828a755c9b9",
    },
  },
};

const mockVerifySignatureFailResponse = {
  data: {
    id: "13",
    type: "verify_payment_signature",
    attributes: {
      status: false,
      rpay_order_id: "order_MHxKAjixDFmvTF",
      razorpay_payment_id: "pay_MHxL86Y0LvquRX",
      razorpay_signature:
        "443770e57550d853bf37ca0eb35edfed7511def73e0c6148e7ace828a755c9b9",
    },
  },
};

const customerDataMock = {
  data: {
    id: "1",
    type: "test",
    attributes: {
      activated: true,
      country_code: null,
      email: "test@gmail.com",
      first_name: "john",
      full_phone_number: "6543456543",
      last_name: "doe",
      phone_number: null,
      type: "test",
      created_at: "date",
      updated_at: "date",
      device_id: null,
      unique_auth_id: "123",
    },
  },
};

const DataArr = [
  {
    id: "1",
    type: "order",
    attributes: {
      status: "scheduled",
      total_fees: 4000,
      total_items: 2,
      total_tax: 44,
      source: null,
      length: null,
      breadth: null,
      weight: null,
      height: null,
      ship_rocket_order_id: "367429835",
      ship_rocket_shipment_id: "366803289",
      ship_rocket_awb_code: "",
      customer: customerDataMock,
      address: {
        data: {
          id: "13",
          type: "address",
          attributes: {
            latitude: null,
            longitude: null,
            address: "dev",
            address_type: "Home",
            city: "Ujjain",
            pincode: 452010,
            state: "MP",
            address2: "med",
            country: "india",
          },
        },
      },
      order_items: {
        data: [
          {
            id: "27",
            type: "order_item",
            attributes: {
              price: 2000,
              quantity: 2,
              taxable: true,
              taxable_value: 22,
              other_charges: null,
              catalogue: {
                data: {
                  id: "1",
                  type: "catalogue",
                  attributes: {
                    sub_category: {
                      id: 1,
                      name: "Pre Primary (kg)",
                      created_at: "2023-05-26T10:41:53.313Z",
                      updated_at: "2023-05-26T10:41:53.313Z",
                      parent_id: null,
                      rank: null,
                    },
                    brand: null,
                    tags: [],
                    reviews: [],
                    name: "demo",
                    sku: "dummy_sku",
                    description: "demo description",
                    manufacture_date: "2000-12-31T00:00:00.000Z",
                    length: null,
                    breadth: null,
                    height: null,
                    stock_qty: null,
                    availability: null,
                    weight: null,
                    price: null,
                    recommended: null,
                    on_sale: null,
                    sale_price: "2000.0",
                    discount: null,
                    category: {
                      id: "1",
                      type: "category",
                      attributes: {
                        id: 1,
                        name: "K12",
                        dark_icon: null,
                        dark_icon_active: null,
                        dark_icon_inactive: null,
                        light_icon: null,
                        light_icon_active: null,
                        light_icon_inactive: null,
                        rank: null,
                        created_at: "2023-05-26T10:41:53.259Z",
                        updated_at: "2023-05-26T10:41:53.259Z",
                        selected_sub_categories: null,
                      },
                    },
                    images: null,
                    average_rating: 0,
                    catalogue_variants: [],
                  },
                },
              },
            },
          },
        ],
      },
    },
  },
];

const orderMockItems = {
  attributes: {
    status: "scheduled",
    total_fees: 4000.0,
    total_items: 2,
    total_tax: 44.0,
    customer: customerDataMock,
    address: {
      data: null,
    },
    order_items: {
      data: [
        {
          id: "4",
          type: "order_item",
          attributes: {
            price: 2000.0,
            quantity: 2,
            taxable: true,
            taxable_value: 22.0,
            other_charges: null,
            catalogue: {
              data: {
                id: "1",
                type: "catalogue",
                attributes: {
                  sub_category: {
                    id: 1,
                    name: "Pre Primary (kg)",
                    created_at: "2023-06-14T06:14:04.512Z",
                    updated_at: "2023-06-14T06:14:04.512Z",
                    parent_id: null,
                    rank: null,
                  },
                  brand: null,
                  tags: [],
                  reviews: [],
                  name: "demo",
                  sku: "dummy_sku",
                  description: "demo description",
                  manufacture_date: "2000-12-31T00:00:00.000Z",
                  length: null,
                  breadth: null,
                  height: null,
                  stock_qty: null,
                  availability: null,
                  weight: null,
                  price: null,
                  recommended: null,
                  on_sale: null,
                  sale_price: "2000.0",
                  discount: null,
                  category: {
                    id: "1",
                    type: "category",
                    attributes: {
                      id: 1,
                      name: "K12",
                      dark_icon: null,
                      dark_icon_active: null,
                      dark_icon_inactive: null,
                      light_icon: null,
                      light_icon_active: null,
                      light_icon_inactive: null,
                      rank: null,
                      created_at: "2023-06-14T06:14:04.468Z",
                      updated_at: "2023-06-14T06:14:04.468Z",
                      selected_sub_categories: null,
                    },
                  },
                  images: null,
                  average_rating: 0,
                  catalogue_variants: [],
                },
              },
            },
          },
        },
      ],
    },
  },
};

const mockOneData = {
  id: 2,
  type: "order",
  attributes: {
    status: "scheduled",
    total_fees: 0.0,
    total_items: 0,
    total_tax: 0.0,
    customer: customerDataMock,
    address: {
      data: null,
    },
    order_items: {
      data: [],
    },
  },
};

const mockData = {
  data: [
    mockOneData,
    {
      ...mockOneData,
      attributes: undefined,
      order_items: {
        data: undefined,
      },
    },
  ],
};

const errorRespMock = {
  error: "order is not pesent!",
  errors: "something is wrong",
};

const createMockResp = {
  data: {
    id: "89",
    type: "razorpay_order",
    attributes: {
      key: "rzp_test_Feaa8XmuED2hRE",
      amount: 50000.0,
      status: "created",
      razorpay_order_id: "order_MI2SCXEF3GwwQK",
      receipt: "3827d7d309de372d27432e69733231bf",
      account_id: 1,
      order_id: 5,
      entity: "order",
      amount_paid: "0.0",
      amount_due: "50000.0",
      currency: "INR",
      notes: {
        key1: "value1",
        key2: "value2",
      },
    },
  },
};

const verifyPaymentRespMock = {
  data: {
    id: "10",
    type: "verify_payment_signature",
    attributes: {
      status: true,
      rpay_order_id: "order_MHxKAjixDFmvTF",
      razorpay_payment_id: "pay_MHxL86Y0LvquRX",
      razorpay_signature:
        "443770e57550d853bf37ca0eb35edfed7511def73e0c6148e7ace828a755c9b9",
    },
  },
};

const HandlerRespMock = {
  razorpay_order_id: "order_MI2SCXEF3GwwQK",
  razorpay_payment_id: "pay_MHxL86Y0LvquRX",
  razorpay_signature:
    "443770e57550d853bf37ca0eb35edfed7511def73e0c6148e7ace828a755c9b9",
};

const mockLocationHistory = {
  state: {
    data: {
      item: {
        id: "1",
        type: "test",
        attributes: {
          key: "check",
          amount: 100,
          status: "completed",
          razorpay_order_id: "11",
          receipt: "generated",
          account_id: 1,
          order_id: 10,
          entity: "check",
          amount_paid: "paid",
          amount_due: "100",
          currency: "INR",
          notes: {
            key1: "test1",
            key2: "test2",
          },
        },
      },
      customerData: {
        data: {
          id: "1",
          type: "test",
          attributes: {
            activated: true,
            country_code: null,
            email: "test@gmail.com",
            first_name: "john",
            full_phone_number: "6543456543",
            last_name: "doe",
            phone_number: null,
            type: "test",
            created_at: "date",
            updated_at: "date",
            device_id: null,
            unique_auth_id: "123",
          },
        },
      },
    },
  },
};

export {
  mockCreateOrderRes,
  mockCreateOrderResError,
  mockCreateOrderResError1,
  mockCreateOrderResWithData,
  mockGetOrderRes,
  mockVerifySignatureErrorResponse1,
  mockVerifySignatureSuccessResponse,
  mockVerifySignatureFailResponse,
  customerDataMock,
  DataArr,
  orderMockItems,
  mockOneData,
  mockData,
  errorRespMock,
  createMockResp,
  verifyPaymentRespMock,
  HandlerRespMock,
  mockLocationHistory,
};
