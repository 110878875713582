import React from "react";

import {
  Container,
  Box,
  // Customizable Area Start
  Modal,
  // Customizable Area End
  CardMedia,
} from "@material-ui/core";

// Customizable Area Start
import { Typography, Button } from "@builder/component-library"
import { imgPasswordVisible } from "./assets";
import { Item } from "./types";
// Customizable Area End

import RazorpayIntegrationController, {
  Props,
  configJSON,
} from "./RazorpayIntegrationController";

export default class RazorpayIntegration extends RazorpayIntegrationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderDetailsModal = () => {
    return (
      <Modal
        open={this.state.isVisible}
        onClose={this.hideModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        data-test-id="modalTestId"
      >
        <Box sx={webStyle.modal}>
          <Box>
            <Box sx={tableStyle.containerModal}>
              <Box sx={tableStyle.header}>{configJSON.idText}</Box>
              <Box sx={{ ...tableStyle.header, ...tableStyle.cellRight }}>{configJSON.priceText}</Box>
              <Box sx={{ ...tableStyle.header, ...tableStyle.cellRight }}>{configJSON.quantityText}</Box>
              <Box sx={{ ...tableStyle.header, ...tableStyle.cellRight }}>{configJSON.taxableText}</Box>
              <Box sx={{ ...tableStyle.header, ...tableStyle.cellRight }}>{configJSON.taxableValueText}</Box>
              <Box sx={{ ...tableStyle.header, ...tableStyle.cellRight }}>{configJSON.catalogueText}</Box>
              <Box sx={{ ...tableStyle.header, ...tableStyle.cellRight }}>{configJSON.actionText}</Box>
              {
                this.state.cartOrderItems.map(
                  (item: Item, index: number) => {
                    const value = item.attributes;
                    const orderSingleItemId = item.id;
                    return (
                      <Box
                        key={index}
                        data-test-id="tableRowId"
                        sx={tableStyle.row}
                      >
                        <Box sx={tableStyle.cell}>
                          {orderSingleItemId}
                        </Box>
                        <Box sx={{ ...tableStyle.cell, ...tableStyle.cellRight }}>
                          {value.price}
                        </Box>
                        <Box sx={{ ...tableStyle.cell, ...tableStyle.cellRight }}>
                          {value.quantity}
                        </Box>
                        <Box sx={{ ...tableStyle.cell, ...tableStyle.cellRight }}>
                          {value?.taxable ? "true" : "false"}
                        </Box>
                        <Box sx={{ ...tableStyle.cell, ...tableStyle.cellRight }}>
                          {value.taxable_value}
                        </Box>
                        <Box sx={{ ...tableStyle.cell, ...tableStyle.cellRight }}>
                          {value.catalogue.data.attributes.name}
                        </Box>
                        <Box sx={{ ...tableStyle.cell, ...tableStyle.cellRight }}>
                          <Button
                            style={{ height: "18px" }}
                            text={configJSON.deleteButtonText}
                            data-test-id={`deleteOrderItem-${index}`}
                            onPress={() => {
                              this.deleteOrderItem(
                                this.state.cartOrderId,
                                parseInt(orderSingleItemId)
                              );
                            }}
                          >
                          </Button>
                        </Box>
                      </Box>
                    )
                  })
              }
            </Box>
          </Box>
          <Box sx={webStyle.closeModalContainer}>
            <Button
              text={configJSON.closeButtonText}
              data-test-id="closeModal"
              onPress={this.hideModal}
            >
            </Button>
          </Box>
        </Box>
      </Modal>
    )
  }

  renderDetailsCheckout = () => {
    return (
      <Modal
        open={this.state.isCheckoutOpen}
        onClose={this.hideCheckoutModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        data-test-id="CheckoutTestId"
      >
        <Box sx={webStyleCheckout.modal}>
          <Box sx={webStyleCheckout.main}>
            <Box sx={webStyleCheckout.paper}>
              <Box>
                <Typography style={webStyleCheckout.heading}>
                  {configJSON.customerDetailsText}
                </Typography>
                <Box sx={webStyleCheckout.infoContainer}>
                  <Typography style={webStyleCheckout.infoSmallHeading}>
                    {configJSON.prefillName} :
                  </Typography>
                  <Typography style={webStyleCheckout.infoText}>
                    {
                      this.state.customerData.data.attributes
                        .first_name
                    }
                  </Typography>
                  <Typography style={webStyleCheckout.infoText}>
                    {
                      this.state.customerData.data.attributes
                        .last_name
                    }
                  </Typography>
                </Box>
                <Box sx={webStyleCheckout.infoContainer}>
                  <Typography style={webStyleCheckout.infoSmallHeading}>
                    {configJSON.amountText} :{" "}
                  </Typography>
                  <Typography style={webStyleCheckout.infoText}>
                    {this.state.razorpayData.data.item.attributes.amount / 100}
                  </Typography>
                </Box>
                <Box sx={webStyleCheckout.infoContainer}>
                  <Typography style={webStyleCheckout.infoSmallHeading}>
                    {configJSON.phoneNumberText} :{" "}
                  </Typography>
                  <Typography style={webStyleCheckout.infoText}>
                    {
                      this.state.customerData.data.attributes
                        .full_phone_number
                    }
                  </Typography>
                </Box>
              </Box>
              <Box sx={webStyleCheckout.paymentTextContainer}>
                <Typography style={webStyleCheckout.paymentText}>
                  {configJSON.razorPayNote}
                </Typography>

                <Button
                  text={configJSON.checkOutButtonText}
                  onPress={this.handlePayment}
                  data-test-id="paymentHandle"
                >
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>
    )
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Container maxWidth="xl">
        <Box>
          <Box sx={tableStyle.container}>
            <Box sx={tableStyle.header}>{configJSON.idText}</Box>
            <Box sx={{ ...tableStyle.header, ...tableStyle.cellRight }}>{configJSON.totalFeesText}</Box>
            <Box sx={{ ...tableStyle.header, ...tableStyle.cellRight }}>{configJSON.totalItemsText}</Box>
            <Box sx={{ ...tableStyle.header, ...tableStyle.cellRight }}>{configJSON.totalTaxText}</Box>
            <Box sx={{ ...tableStyle.header, ...tableStyle.cellRight }}>{configJSON.customerText}</Box>
            <Box sx={{ ...tableStyle.header, ...tableStyle.cellRight }}>{configJSON.actionText}</Box>
            {this.state.cartOrderList &&
              this.state.cartOrderList.length !== 0 ? (
              this.state.cartOrderList.map((item, index) => {
                const value = item.attributes;
                const orderItemId: string = item.id;
                return (
                  <Box
                    key={index}
                    data-test-id="tableRowId"
                    sx={tableStyle.row}
                  >
                    <Box sx={tableStyle.cell}>{orderItemId}</Box>
                    <Box sx={{ ...tableStyle.cell, ...tableStyle.cellRight }}>
                      {value.total_fees}
                    </Box>
                    <Box sx={{ ...tableStyle.cell, ...tableStyle.cellRight }}>
                      {value.total_items}
                    </Box>
                    <Box sx={{ ...tableStyle.cell, ...tableStyle.cellRight }}>
                      {value.total_tax}
                    </Box>
                    <Box sx={{ ...tableStyle.cell, ...tableStyle.cellRight }} data-test-id={`firstNameTestId-${index}`}>
                      {`${value.customer.data.attributes.first_name} ${value.customer.data.attributes.last_name}`}
                    </Box>
                    <Box sx={{ ...tableStyle.cell, ...tableStyle.cellRight }}>
                      <Button
                        style={{ height: "18px" }}
                        text={configJSON.viewButtonText}
                        data-test-id={`viewOrderItems-${index}`}
                        onPress={() => {
                          this.showOrderApi(
                            item.attributes.order_items.data,
                            orderItemId
                          );
                        }}
                      >
                        <CardMedia
                          component="img"
                          height="30px"
                          width="30px"
                          image={imgPasswordVisible}
                          alt="eye"
                        />
                      </Button>
                      <Button
                        style={{ height: "18px" }}

                        text={configJSON.checkOutButtonTextTwo}
                        data-test-id={`checkout-${index}`}
                        onPress={() => {
                          this.handleCheckout(item);
                        }}
                      >
                      </Button>
                    </Box>
                  </Box>
                )
              })) : (
              <Typography style={webStyle.note}>
                {configJSON.noOrdersToDisplay}
              </Typography>
            )
            }
          </Box>
        </Box>
        {this.renderDetailsModal()}
        {this.renderDetailsCheckout()}
      </Container>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyleCheckout = {
  main: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    border: ".2px solid #000",
    padding: "10px",
    borderRadius: "10px",
  },
  heading: {
    color: "rgb(98, 0, 238)",
    textDecoration: "underline",
  },
  infoContainer: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  infoText: {
    textTransform: "capitalize" as "capitalize",
    margin: "0 4px",
  },
  paymentText: {
    margin: "10px 0",
    fontSize: 18,
    color: "black",
  },
  paymentTextContainer: {
    margin: "50px 0",
    height: "50px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  infoSmallHeading: { width: "130px", textAlign: "left" as "left" },
  modal: {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    minWidth: 600,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  },
};

const tableStyle = {
  container: {
    display: 'grid',
    gridTemplateColumns: 'repeat(6, 1fr)',
    gap: 2,
    alignItems: 'center',
    padding: 2,
    border: '1px solid #ddd',
    borderRadius: '4px',
  },
  containerModal: {
    display: 'grid',
    gridTemplateColumns: 'repeat(7, 1fr)',
    gap: 2,
    alignItems: 'center',
    padding: 2,
    border: '1px solid #ddd',
    borderRadius: '4px',
  },
  header: {
    fontWeight: 'bold',
    padding: '10px',
    backgroundColor: '#f5f5f5',
    borderBottom: '2px solid #ddd',
  },
  cell: {
    cursor: 'pointer',
    padding: '10px',
    borderBottom: '1px solid #ddd',
  },
  cellRight: {
    textAlign: 'right',
    display: "flex"
  },
  mainTitle: {
    color: 'rgb(98, 0, 238)',
  },
  row: {
    display: 'contents',
    cursor: 'pointer',
  },
};
const webStyle = {
  tableContainer: {
    maxHeight: 440,
  },
  paper: {
    width: "100%",
    overflow: "hidden",
  },
  modal: {
    width: "64%",
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    minWidth: 600,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  },
  modalAddButton: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  closeModalContainer: {
    display: "flex",
    justifyContent: "flex-end",
    padding: "10px 0px",
  },
  paperContainer: {
    width: "100%",
    overflow: "hidden",
  },
  note: {
    width: "100%" as "100%",
    textAlign: "center" as "center",
  },
};
// Customizable Area End
