import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { mockCreateOrderRes } from "../__mocks__/mocks";
import { CreateOrderResponse, GetOrderResponse } from "./types";
import RazorpayCheckout from "react-native-razorpay";
export const configJSON = require("./config");
// Customizable Area End

// Customizable Area Start
interface RazorPayResponse {
  razorpay_order_id: string;
  razorpay_payment_id: string;
  razorpay_signature: string;
}
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  enableField: boolean;
  // Customizable Area Start
  name: string;
  email: string;
  contactNumber: string;
  amount: number;
  orderId: string;
  createOrderRes: CreateOrderResponse;
  orderList: GetOrderResponse[];
  token: string;
  razorpayKey: string;
  razorpayAccessToken: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class RazorpayIntegration6Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getTokenApiCallId: string = "";
  getRazorpayConfigSetUpCallId: string = "";
  getOrdersApiCallId: string = "";
  postCreateOrdersApiCallId: string = "";
  putVerifyPaymentApiCallId: string = "";
  postAnalyticsApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      enableField: false,
      // Customizable Area Start
      name: "",
      email: "",
      contactNumber: "",
      amount: 0,
      orderId: "",
      createOrderRes: mockCreateOrderRes,
      orderList: [],
      token: "",
      razorpayKey: "",
      razorpayAccessToken: ""
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Received", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage),
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage),
      );
      let errorResponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage),
      );
      if (errorResponse) {
        this.parseApiCatchErrorResponse(errorResponse);
      } else if (responseJson) {
        this.handleResponse(message, apiRequestCallId);
      }
    }
    // Customizable Area End
  }
  // Customizable Area Start

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
  }

  handleResponse = (message: Message, apiRequestCallId: string) => {
    switch (apiRequestCallId) {
      case this.postCreateOrdersApiCallId:
        this.handleCreateOrder(message);
        break;
      case this.getOrdersApiCallId:
        this.handleOrderList(message);
        break;
      case this.putVerifyPaymentApiCallId:
        this.handleVerifyPayment(message);
        break;
        case this.getTokenApiCallId:
          this.handleToken(message)
        break
        case this.getRazorpayConfigSetUpCallId:
          this.handleRazorpayConfigSetUp(message)
        break
    }
  };

  handleToken = (message: Message) =>{
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage),
    );
    this.setState({ token: responseJson.token }, () => {
      this.getOrders();
      this.getRazorpayConfigSetUp();
    });
  }

  handleRazorpayConfigSetUp = (message: Message) =>{
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage),
    );
    this.setState({ 
      razorpayAccessToken: responseJson.razorpay_access_token,
      razorpayKey: responseJson.razorpay_key 
     });
  }

  handleCreateOrder = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage),
    );
    if (responseJson.error) {
      this.showAlert("", responseJson.error);
    } else {
      this.setState({ createOrderRes: responseJson }, () => {
        this.startRazorPayCheckout();
      });
    }
  };

  handleOrderList = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage),
    );
    if (responseJson.message) {
      this.showAlert("Alert", responseJson.message);
      this.setState({ orderList: [] });
    } else if (responseJson.error) {
      this.showAlert("Alert", responseJson.error);
      this.setState({ orderList: [] });
    } else if (responseJson.data) {
      this.setState({ orderList: responseJson.data });
    }
  };

  handleVerifyPayment = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage),
    );
    if (responseJson.error) {
      this.showAlert("", responseJson.error);
    } else if (responseJson.data.attributes.status === true) {
      this.showAlert("", configJSON.msgPaymentSuccess);
    } else if (responseJson.data.attributes.status === false) {
      this.showAlert("", configJSON.msgPaymentFailed);
    }
  };

  setCheckoutData = () => {
    const orderItem = this.state.orderList[0];
    const { attributes, id } = orderItem;
    this.setState(
      {
        amount: attributes.total_fees + attributes.total_tax,
        email: attributes.customer.data.attributes.email,
        name: `${attributes.customer.data.attributes.first_name} ${attributes.customer.data.attributes.last_name}`,
        contactNumber: attributes.customer.data.attributes.full_phone_number,
        orderId: id,
      },
      () => {
        this.startRazorPayPayment();
      },
    );
  };

  startRazorPayCheckout = () => {
    const { contactNumber, email, name, amount, createOrderRes, razorpayKey } = this.state;
    const options = {
      order_id: createOrderRes.data.attributes.razorpay_order_id,
      description: configJSON.description,
      currency: configJSON.currencyValue,
      key: razorpayKey,
      amount: amount * 100,
      name: configJSON.projectNameText,
      prefill: {
        email: email,
        contact: contactNumber,
        name: name,
      },
      theme: { color: configJSON.themeColor },
    };
    RazorpayCheckout.open(options)
      .then(() => {
        // handle success
        this.handleAnalytics()
      })
      .catch((error) => {
        // handle failure
        this.showAlert(configJSON.errorTitle, `${error.description}`);
      });
  };

  startRazorPayPayment = async () => {
    const { orderId, amount } = this.state;
    if (amount <= 0) {
      return;
    }
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      "token": this.state.token,
      "Authorization": this.state.razorpayAccessToken
    };
    const bodyHttp = {
      data: {
        amount: amount * 100,
        currency: configJSON.currencyValue,
        notes: {
          key1: "value1",
          key2: "value2",
        },
      },
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );
    this.postCreateOrdersApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header),
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(bodyHttp),
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.createOrderApiEndPoint}${orderId}/create_order`,
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethod,
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  verifyPayment = async (paymentData: RazorPayResponse) => {
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      "token": this.state.token
    };
    const bodyHttp = {
      razorpay_order_id: paymentData.razorpay_order_id,
      razorpay_payment_id: paymentData.razorpay_payment_id,
      razorpay_signature: paymentData.razorpay_signature,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );
    this.putVerifyPaymentApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header),
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(bodyHttp),
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.putOrdersUpdateApiEndPoint}`,
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethod,
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getOrders = async () => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      "token": this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );
    const params = { filter_by: "scheduled" };

    this.getOrdersApiCallId = requestMessage.messageId;
    let urlParams = new URLSearchParams(params).toString();

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod,
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getOrdersApiEndPoint}?${urlParams}`,
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header),
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType,
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getToken = async () => {
    const header = {
      "Content-Type": configJSON.apiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );
    this.getTokenApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
     `${configJSON.createTokenApiEndPoint}email=example@user.com&password=password1234`,
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header),
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethod,
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getRazorpayConfigSetUp = () => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      "token": this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );

    this.getRazorpayConfigSetUpCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod,
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.razorpayConfigApiEndPoint,
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header),
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleAnalytics = () => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      "token": this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );
    this.postAnalyticsApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header),
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_razorpay/razorpay/${this.state.orderId}/send_analytics?razorpay_order_id=${this.state.createOrderRes.data.attributes.razorpay_order_id}`,
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethod,
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };
  // Customizable Area End
}
